<template>
  <div class="j-footer-article mx-auto px-lg-0 px-4">
    <p>
      <general-text
        :text-types="TEXT_TYPES.title"
        dictionary-key="footer_article_main_title"
        class="my-4 d-block"
      />
      <general-text
        class="d-inline"
        :text="firstParagraphArticleText"
      />
      <v-btn
        v-if="!showFullArticle"
        variant="text"
        :color="scssVariables.jColorTextRegular"
        class="text-none h-auto px-0 ml-3 text-body-1"
        @click="expandArticle"
      >
        <span class="j-text j-text--white">
          {{ $t('general_show_more') }}
        </span>
      </v-btn>
    </p>
    <v-slide-y-transition
      tag="div"
      group
    >
      <div v-if="showFullArticle">
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.title"
            dictionary-key="footer_article_advantage_title"
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_advantage_text" />
        </p>
        <p class="my-6">
          <general-text-list
            :items-list="footerArticleAdvantageList"
            text-list-items
            no-items-gap
          />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.title"
            dictionary-key="footer_article_games_and_providers_title"
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_games_and_providers_text_one" />
        </p>
        <p class="my-6">
          <general-text-list
            :items-list="footerArticleGamesAndProvidersList"
            text-list-items
            no-items-gap
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_games_and_providers_text_two" />
        </p>
        <p class="my-6">
          <general-text-list
            :items-list="ARTICLE_GAMES_PROVIDERS_LIST"
            text-list-items
            :column-count="2"
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_games_and_providers_text_three" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.title"
            dictionary-key="footer_article_free_game_title"
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_free_game_text" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.title"
            dictionary-key="footer_article_game_for_money_title"
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_game_for_money_text" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.title"
            dictionary-key="footer_article_peculiarities_title"
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_peculiarities_text_one" />
        </p>
        <p class="my-6">
          <general-text-list
            :items-list="footerArticlePeculiaritiesList"
            text-list-items
            no-items-gap
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_peculiarities_text_two" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.title"
            dictionary-key="footer_article_bonuses_title"
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_bonuses_text_one" />
        </p>
        <p class="my-6">
          <general-text-list
            :items-list="footerArticleBonusesList"
            text-list-items
            no-items-gap
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_bonuses_text_two" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.title"
            dictionary-key="footer_article_tournaments_title"
          />
        </p>
        <p class="my-2">
          <general-text dictionary-key="footer_article_tournaments_text" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.title"
            dictionary-key="footer_article_payment_systems_title"
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_payment_systems_text_one" />
        </p>
        <p class="my-6">
          <general-text-list
            :items-list="footerArticlePaymentSystem"
            text-list-items
            no-items-gap
          />
        </p>
        <p class="my-2">
          <general-text dictionary-key="footer_article_payment_systems_text_two" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.title"
            dictionary-key="footer_article_registration_title"
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_registration_text_one" />
        </p>
        <p class="my-6">
          <general-text-list
            :items-list="footerArticleRegistrationList"
            text-list-items
            no-items-gap
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_registration_text_two" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.title"
            dictionary-key="footer_article_technical_support_title"
          />
        </p>
        <p>
          <general-text dictionary-key="footer_article_technical_support_text_one" />
        </p>
        <p class="my-6">
          <general-text-list
            :items-list="footerArticleSupportList"
            text-list-items
            no-items-gap
          />
        </p>
        <p class="my-2">
          <general-text dictionary-key="footer_article_technical_support_text_two" />
        </p>
        <p class="my-2">
          <general-text dictionary-key="footer_article_technical_support_text_three" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.title"
            dictionary-key="footer_article_faq_title"
          />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.subTitle"
            dictionary-key="footer_article_faq_subtitle_one"
          />
        </p>
        <p class="my-2">
          <general-text dictionary-key="footer_article_faq_text_one" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.subTitle"
            dictionary-key="footer_article_faq_subtitle_two"
          />
        </p>
        <p class="my-2">
          <general-text dictionary-key="footer_article_faq_text_two" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.subTitle"
            dictionary-key="footer_article_faq_subtitle_three"
          />
        </p>
        <p class="my-2">
          <general-text dictionary-key="footer_article_faq_text_three" />
        </p>
        <p class="my-3">
          <general-text
            :text-types="TEXT_TYPES.subTitle"
            dictionary-key="footer_article_faq_subtitle_four"
          />
        </p>
        <p class="my-2">
          <general-text dictionary-key="footer_article_faq_text_four" />
        </p>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script setup lang="ts">
import { TEXT_TYPES } from '~/constants/general';
import { ARTICLE_GAMES_PROVIDERS_LIST } from '../constants';

const { t } = useI18n();
const scssVariables = useScssVariables();
const showFullArticle = ref(false);
const firstParagraphArticleText = computed(() => {
  return showFullArticle.value
    ? `${t('footer_article_first_paragraph_minimized')} ${t('footer_article_first_paragraph_addition')}`
    : `${t('footer_article_first_paragraph_minimized')}...`;
});
const expandArticle = () => showFullArticle.value = true;
const footerArticleAdvantageList = computed(() => JSON.parse(t('footer_article_advantage_list')));
const footerArticleGamesAndProvidersList = computed(() => JSON.parse(t('footer_article_games_and_providers_list')));
const footerArticlePeculiaritiesList = computed(() => JSON.parse(t('footer_article_peculiarities_list')));
const footerArticleBonusesList = computed(() => JSON.parse(t('footer_article_bonuses_list')));
const footerArticleRegistrationList = computed(() => JSON.parse(t('footer_article_registration_list')));
const footerArticleSupportList = computed(() => JSON.parse(t('footer_article_technical_support_list')));
const footerArticlePaymentSystem = computed(() => JSON.parse(t('general_payment_systems')));
</script>

<style lang="scss" scoped>
.j-footer-article {
  max-width: 1171px;
  margin-top: 44px;
  @media(min-width: 1280px) {
    margin-top: 123px;
  }
}
</style>
