<template>
  <div class="j-footer mt-16">
    <template v-if="useLocalePrefixForHref('/') === route.path">
      <j-footer-parts-banner />
      <j-footer-parts-article />
    </template>
    <j-footer-parts-items-list
      :items-list="PAYMENT_LOGOS"
      extra-horizontal-margin
      class="mt-10 mt-lg-16 pt-lg-5 mb-7 mb-lg-10"
    />
    <v-divider />
    <j-footer-parts-items-list
      :items-list="FAQ_ITEMS"
      vertical-margin
      text-items
      class="mt-10 mb-8 mb-lg-4"
    />
    <v-divider v-if="!isMobileMode" />
    <div class="j-footer__row j-footer__row--mw-910 mx-auto">
      <j-footer-parts-items-list
        :items-list="PROVIDERS_LOGOS"
        is-hover-effect
        class="mt-lg-16 mb-8 mb-lg-11"
      />
    </div>
    <v-divider />
    <j-footer-parts-items-list
      :items-list="INFO_LOGOS"
      is-hover-effect
      class="mt-10"
    />
    <div class="j-footer__text j-footer__text--mw-1171 text-center mx-auto pt-0 pt-lg-12 px-5 px-lg-0">
      <general-text
        v-for="(paragraphText, index) of licenseText"
        :key="index"
        class="mb-8"
        :text="paragraphText"
      />
      <general-text
        class="mb-8"
        :text="copyrightText"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import  {
  PROVIDERS_LOGOS,
  PAYMENT_LOGOS,
  INFO_LOGOS,
  FAQ_ITEMS,
} from './constants';

const isMobileMode = inject('isMobileMode');
const { t } = useI18n();
const route = useRoute();
const licenseText = computed(() => JSON.parse(t('footer_license')));
const copyrightText = computed(() => {
  const currentYear = new Date().getFullYear();

  return `Copyright © 2020-${currentYear}. ${t('footer_all_rights_reserved')}`;
});
</script>

<style lang="scss" scoped>
.j-footer {
  padding-top: 44px;
  @media(min-width: 1280px) {
    padding-top: 77px;
  }
  @media(min-width: 1400px) {
    padding-top: 135px;
  }
}

.j-footer__text {
  color: $j-color-text-secondary;
  &.j-footer__text--mw-1171 {
    max-width: 1171px;
  }
}

.j-footer__row {
  &.j-footer__row--mw-910 {
    max-width: 910px;
  }
}
</style>
