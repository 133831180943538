export const ARTICLE_GAMES_PROVIDERS_LIST = [
  '1x2gaming;',
  'Amatic;',
  'Betsoft;',
  'Big Time Gaming;',
  'Blueprint;',
  'Bombay Live;',
  'Booming Games;',
  '3 Oaks Gaming (Booongo);',
  'Endorphina;',
  'Evolution Gaming;',
  'Gaming Corps;',
  'Habanero;',
  'Hacksaw Gaming;',
  'Iron Dog Studio;',
  'Just For The Win (JFTW);',
  'Microgaming;',
  'NetEnt;',
  'Nolimit City;',
  'OneTouch;',
  'Playmer;',
  'Play\'n GO;',
  'Playson;',
  'Pragmatic Play;',
  'Push Gaming;',
  'Quickspin;',
  'Red Tiger;',
  'Relax Gaming;',
  'Retro Gaming;',
  'Rogue;',
  'Spinomenal;',
  'Thunderkick;',
  'Wazdan;',
  'Yggdrasil',
  'Playtech',
  'Belatra',
];

export const TRUSTLOGO_VALIDATION_PLACEHOLDER = 'trustlogoValidationPlaceholder';

export const INFO_LOGOS = [
  {
    route: 'https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbkp4UlcweWNUYzJTbmh1UjJKdWVFYzFUM2R1WkdjOVBTSXNJblpoYkhWbElqb2lVVWxPUmtwUVUxQTVXRlJHT1dWalNqVnJjVzlEVVQwOUlpd2liV0ZqSWpvaU5EWTROMll3T1RZeVltUXdPRGd6WlRVeU9UZzROMlUxWmpNMFpUVTROamd6TXpkak5USmlNMkkzWVRZek5EbGxZMk0xTjJNMU5tTTVZak13TnpFeE1pSXNJblJoWnlJNklpSjk=',
    iconName: 'curacao',
    iconWidth: 150,
    iconHeight: 85,
  },
  {
    route: '/responsible-gaming',
    iconName: '18+',
    iconWidth: {
      desktop: 56,
      mobile: 41,
    },
  },
  {
    route: TRUSTLOGO_VALIDATION_PLACEHOLDER,
    iconName: 'comodo',
    iconWidth: {
      desktop: 147,
      mobile: 107,
    },
  },
  {
    route: 'https://t.me/jozzcasino',
    iconName: 'telegram',
    iconWidth: {
      desktop: 56,
      mobile: 41,
    },
  },
];

export const PAYMENT_LOGOS = [
  {
    iconName: 'visa',
    iconWidth: 54,
  },
  {
    iconName: 'maestro',
    iconWidth: 65,
  },
  {
    iconName: 'webmoney',
    iconWidth: 44,
  },
  {
    iconName: 'footer_astropay',
    iconWidth: 104,
  },
  {
    iconName: 'skrill',
    iconWidth: 82,
  },
  {
    iconName: 'netewer',
    iconWidth: 138,
  },
  {
    iconName: 'payOp',
    iconWidth: 83,
  },
];

export const PROVIDERS_LOGOS = [
  {
    route: '/games/1x2gaming/all',
    iconName: '1x2gaming',
    iconWidth: 72,
  },
  {
    route: '/games/amatic/all',
    iconName: 'amatic',
    iconWidth: 69,
  },
  {
    route: '/games/bsg/all',
    iconName: 'betsoft',
    iconWidth: 62,
  },
  {
    route: '/games/big-time-gaming-evolution/all',
    iconName: 'bigtimegaming',
    iconWidth: 87,
  },
  {
    route: '/games/blueprint/all',
    iconName: 'blueprint',
    iconWidth: 73,
  },
  {
    route: '/games/hub88-live88/all',
    iconName: 'live88',
    iconWidth: 85,
  },
  {
    route: '/games/booming-games/all',
    iconName: 'booming',
    iconWidth: 66,
  },
  {
    route: '/games/booongo/all',
    iconName: 'booongo',
    iconWidth: 90,
  },
  {
    route: '/games/endorphina/all',
    iconName: 'endorphina',
    iconWidth: 59,
  },
  {
    route: '/games/evolution/all',
    iconName: 'evolution_gaming',
    iconWidth: 102,
  },
  {
    route: '/games/gaming-corps/all',
    iconName: 'gaming_corps',
    iconWidth: 82,
  },
  {
    route: '/games/habanero/all',
    iconName: 'habanero',
    iconWidth: 92,
  },
  {
    route: '/games/hacksaw-gaming-relax/all',
    iconName: 'hacksaw',
    iconWidth: 67,
  },
  {
    route: '/games/iron-dog-studio/all',
    iconName: 'irondog-studio',
    iconWidth: 82,
  },
  {
    route: '/games/just-for-the-win-jftw/all',
    iconName: 'justforwin-footer',
    iconWidth: 55,
  },
  {
    route: '/games/microgaming/all',
    iconName: 'microgaming',
    iconWidth: 80,
  },
  {
    route: '/games/netent-evolution/all',
    iconName: 'netent',
    iconWidth: 57,
  },
  {
    route: '/games/nolimit/all',
    iconName: 'nolimit',
    iconWidth: 74,
  },
  {
    route: '/games/hub88-one-touch/all',
    iconName: 'one_touch',
    iconWidth: 82,
  },
  {
    route: '/games/playmer-sensei/all',
    iconName: 'playmer',
    iconWidth: 82,
  },
  {
    route: '/games/playngo/all',
    iconName: 'play_n_go',
    iconWidth: 72,
  },
  {
    route: '/games/playson/all',
    iconName: 'playson',
    iconWidth: 78,
  },
  {
    route: '/games/pragmatic-play/all',
    iconName: 'pragmatic',
    iconWidth: 80,
  },
  {
    route: '/games/push-gaming/all',
    iconName: 'pushgaming',
    iconWidth: 95,
  },
  {
    route: '/games/quickspin/all',
    iconName: 'quckspin',
    iconWidth: 75,
  },
  {
    route: '/games/redtiger-evolution/all',
    iconName: 'red_tiger',
    iconWidth: 87,
  },
  {
    route: '/games/relax-gaming/all',
    iconName: 'relaxgaming',
    iconWidth: 64,
  },
  {
    route: '/games/spinomenal-retro-games/all',
    iconName: 'retro_gaming',
    iconWidth: 77,
  },
  {
    route: '/games/rogue/all',
    iconName: 'rogue',
    iconWidth: 37,
  },
  {
    route: '/games/spinomenal/all',
    iconName: 'spinomenal',
    iconWidth: 111,
  },
  {
    route: '/games/thunderkick/all',
    iconName: 'thunderkick',
    iconWidth: 80,
  },
  {
    route: '/games/wazdan/all',
    iconName: 'wazdan',
    iconWidth: 97,
  },
  {
    route: '/games/yggdrasil/all',
    iconName: 'yggdrasil_gaming',
    iconWidth: 80,
  },
  {
    route: '/games/playtech/all',
    iconName: 'playtech',
    iconWidth: 82,
  },
  {
    route: '/games/belatra/all',
    iconName: 'belatra',
    iconWidth: 75,
  },
];

export const FAQ_ITEMS = [
  {
    dictionaryKey: 'general_about_us',
    route: '/about-us',
  },
  {
    dictionaryKey: 'footer_responsible_gaming',
    route: '/responsible-gaming',
  },
  {
    dictionaryKey: 'footer_for_partners',
    route: 'https://gamblingcraft.com',
  },
  {
    dictionaryKey: 'footer_terms_and_conditions',
    route: '/terms-and-conditions',
  },
  {
    dictionaryKey: 'footer_cookie_policy',
    route: '/cookie-policy',
  },
  {
    dictionaryKey: 'footer_privacy_policy',
    route: '/privacy-policy',
  },
  {
    dictionaryKey: 'footer_self_exclusion',
    route: '/self-exclusion',
  },
  {
    dictionaryKey: 'footer_dispute_resolution',
    route: '/dispute-resolution',
  },
  {
    dictionaryKey: 'footer_aml_kyc',
    route: '/aml-kyc',
  },
  {
    dictionaryKey: 'footer_fairness_rng',
    route: '/fairness-rng',
  },
  {
    dictionaryKey: 'footer_accounts_payouts_bonuses',
    route: '/account-payouts-bonuses',
  },
];
